/* eslint max-len: 0 */
import Enum from 'enum';

export const Resources = new Enum({
  ProviderSchedule: 'providers/:providerId/schedule',
  ProviderScheduleItem: 'providers/:providerId/schedule/:shiftId',
  ProviderAvailableShifts: 'providers/:providerId/available_shifts',
  ProviderRegion: 'providers/:providerId/regions/:regionId',
  ProviderRegions: 'providers/:providerId/regions',
  ProviderAvailableRegions: 'providers/:providerId/available_regions',
  ProviderFacilities: 'providers/:providerId/facilities',
});
