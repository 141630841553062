import Enum from 'enum';

export const Resources = new Enum({
  Account: 'account',
  AccountDOB: 'account/dob',
  AccountForgotPassword: 'account/forgot_password',
  AccountHepB: 'account/hep_b',
  AccountHomeBase: 'account/home_base',
  AccountMailingAddress: 'account/mailing_address',
  AccountOAIOptIn: 'account/oai_opt_in',
  AccountOAIStartedAt: 'account/oai_started_at',
  AccountSSN: 'account/ssn',
  AccountSpecialty: 'account/specialty',
  AccountSpecialtyUnlisted: 'account/specialty/unlisted',
  AccountSpecialtyFuture: 'account/specialty/future',
  AccountW9: 'account/w9',
  AccountResetDormant: 'account/reset_dormant',
  CovidVaxManufacturers: 'covid_vax/manufacturers',
  CredentialRequirements: 'credential-requirements',
  DocumentPage: 'documents/:userRequiredDocumentId/pages/:pageNumber',
  DocumentPages: 'documents/:userRequiredDocumentId/pages',
  DocumentUploadPage: 'documents/:userRequiredDocumentId/upload/pages/:pageNumber',
  DocumentUploadPages: 'documents/:userRequiredDocumentId/upload/pages',
  EmergencyContact: 'users/:userId/emergency_contacts/:emergencyContactId',
  EmergencyContacts: 'users/:userId/emergency_contacts',
  EmergencyContactsConfig: 'users/:userId/emergency_contacts_config',
  CredentialingFacilityRequiredDocuments: 'facilities/:facilityId/facility-required-documents',
  LocalizedSpecialities: 'localized_specialties',
  OaiDocumentStatuses: 'oai_document_statuses',
  OaiDocuments: 'oai_documents',
  PhoneRequestSmsVerification: 'onboarding/phone/request-verification',
  PhoneRequestVoiceCallVerification: 'onboarding/phone/request-voice-call-verification',
  PhoneVerify: 'onboarding/phone/verify',
  PublicSpecialties: 'specialties',
  TbTestDocumentTestType: 'documents/:userRequiredDocumentId/tb_test_document_test_types/:tbTestDocumentTestTypeId',
  TbTestDocumentTestTypes: 'documents/:userRequiredDocumentId/tb_test_document_test_types',
  TbTestTypeDefinitions: 'documents/:userRequiredDocumentId/tb_test_type_definitions',
  UserAcceptedTOSVersion: 'users/:userId/account/accepted_tos_version',
  UserAccountOAIData: 'users/:userId/account/oai_data',
  UserAccountSmsOptIn: 'users/:userId/account/sms_opt_in',
  UserApplicableSkills: 'user/applicable_skills',
  UserCovidVax: 'users/:userId/covid_vax',
  UserCovidVaxDocument: 'users/:userId/covid_vax/documents/:documentId',
  UserCovidVaxDocuments: 'users/:userId/covid_vax/documents',
  UserCovidVaxDose: 'users/:userId/covid_vax/doses/:doseId',
  UserCovidVaxDoses: 'users/:userId/covid_vax/doses',
  UserCovidVaxStatus: 'users/:userId/covid_vax/status',
  UserCredentialsBackgroundCheckRequest: 'users/:userId/credentials/background_check/request',
  UserSkills: 'user/skills',
  UserVerifyPhoneConfirm: 'user/verify-phone/confirm',
  UserVerifyPhoneSend: 'user/verify-phone/send',
  UserValidationCodeSend: 'user/validation_code/send',
  UserValidationCodeVerify: 'user/validation_code/verify',
  UserSuggestedCredentials: 'users/:userId/credentials/suggested-credentials',
});
