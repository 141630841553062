import Enum from 'enum';

export const Resources = new Enum({
  Timesheet: 'timesheets/:timesheetId',
  Timesheets: 'timesheets',
  Worklog: 'providers/:providerId/worklogs/:shiftWorklogId',
  Worklogs: 'providers/:providerId/worklogs',
  TimesheetRejectionNotes: 'timesheet_rejection_notes',
  ProviderDigitalInvoice: 'provider_digital_invoice/:providerDigitalInvoiceId',
  ProviderDigitalInvoicePaymentDetails: 'provider_digital_invoice/:providerDigitalInvoiceId/payment_details',
  ProviderDigitalInvoices: 'provider_digital_invoice',
  ProviderShiftsNotAttended: 'providers/:providerId/shifts_not_attended',
  FacilityAddressGeofence: 'facilities/:facilityId/address/geofence',
  FacilityGeofenceError: 'facilities/:facilityId/geofence_errors',
});
