import Vue from 'vue';
import Enum from 'enum';

/**
 * Global API Event Bus
 */
class _ShiftkeyAPIEventBus {
  constructor() {
    this.bus = new Vue();
    this.events = new Enum([
      'UnauthorizedRequest'
    ])
  }
  /**
   * Emits and event defined by the events Enum.
   *
   * @param event
   */
  emit(event) {
    this.bus.$emit(event.toString());
  }
}

export const ShiftkeyAPIEventBus = new _ShiftkeyAPIEventBus();
