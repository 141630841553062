/* eslint max-len: 0 */
import Enum from 'enum';

export const Resources = new Enum({
  ClientRoles: 'client_roles',
  ClientFacilities: 'clients/:clientId/facilities',
  ClientFacility: 'clients/:clientId/facilities/:facilityId',
  Clients: 'clients',
  ClientsOrphan: 'clients/orphan/:clientId',
  EmergencyBroadcast: 'emergency_broadcasts/:emergencyBroadcastId',
  EmergencyBroadcastApproval: 'emergency_broadcasts/:emergencyBroadcastId/approval',
  EmergencyBroadcastLimits: 'emergency_broadcasts/limits',
  EmergencyBroadcastLimit: 'emergency_broadcasts/limits/:limitId',
  EmergencyBroadcasts: 'emergency_broadcasts',
  Facilities: 'facilities',
  Facility: 'facilities/:facility_id',
  FacilityAddress: 'facilities/:facilityId/address',
  FacilityBillingContact: 'facilities/:facilityId/billing_contacts/:billingContactId',
  FacilityBillingContacts: 'facilities/:facilityId/billing_contacts',
  FacilityContractedProvider: 'facilities/:facility_id/contracted_providers/:contractedProviderId',
  FacilityContractedProviders: 'facilities/:facility_id/contracted_providers',
  FacilityContractedProviderUpdateSummary: 'facilities/:facility_id/contracted_providers/:contracted_provider_id/update_summary',
  FacilityCustomEasyAssign: 'facilities/:facility_id/custom-easy-assign',
  FacilityEmergencyBroadcastsLimit: 'facilities/:facility_id/emergency-broadcasts-limit',
  FacilityGainPermissionsContacts: 'facilities/:facilityId/gain_permissions_contacts',
  FacilityOrientedProviders: 'facilities/:facilityId/oriented-providers',
  FacilityRequiredDocuments: 'facilities/:facilityId/facility-required-documents',
  FacilityPremiumRates: 'facilities/premium-rates',
  FavoritableProviders: 'favoritable-providers',
  InternalFacilityDocument: 'facilities/:facilityId/internal_documents/:documentId',
  InternalFacilityDocuments: 'facilities/:facilityId/internal_documents',
  Marketer: 'facilities/:facilityId/marketer',
  FacilityPeople: 'facilities/:facilityId/people',
  FacilityPPDReport: 'facilities/:facilityId/ppd_report',
  FacilityShiftDefinition: 'facilities/:facility_id/shift_definitions/:shiftDefinitionId',
  FacilityShiftDefinitions: 'facilities/:facility_id/shift_definitions',
  FacilityTeam: 'facilities/:facilityId/team/:teamId',
  FacilityTeamClients: 'facilities/:facilityId/team/:teamId/clients',
  FacilityTerms: 'facilities/:facilityId/terms',
  FacilityTypes: 'facility_types',
  FacilityFavoriteProvider: 'facility/:facilityId/favorite_provider/:providerId',
  FacilityFeatureFlag: 'facility_feature_flag/:id',
  FacilityFeatureFlagGlobal: 'facility_feature_flag/:id/global',
  OnShiftInitActivation: 'onshift/init-activation',
  OnShiftActivate: 'onshift/activate',
  OnShiftDeactivate: 'onshift/:facilityId/deactivate',
  ProviderBatchDnr: 'providers/:providerId/batch_dnr',
  ProviderDnr: 'providers/:providerId/dnrs/:dnrId',
  ProviderDnrs: 'providers/:providerId/dnrs',
  ProviderFlag: 'providers/:providerId/flags/:flagId',
  ProvidersFlags: 'providers/:providerId/flags',
  SystemSchedule: 'schedule',
  SystemScheduleDaysListWithShifts: 'schedule/days-list-with-shifts',
  SystemScheduleItem: 'schedule/:shift_opening_id',
  ShiftAssignmentResolution: 'shift_assignments/:shift_assignment_id/resolution',
  ShiftOpeningAvailableProviders: 'shift_openings/:shiftOpeningId/available_providers',
  Shift: 'shifts/:shiftId',
  ShiftInformation: 'shifts/:facilityId/information',
  ShiftsCount: 'shifts/count',
  ShiftsReportSummary: 'shifts_report_summary',
  ShiftsReport: 'shifts_report',
  TeamPermissionTypes: 'teams/permission_types',
  Team: 'teams/:teamId',
  Teams: 'teams',
  ProviderShiftRatings: 'providers/:providerId/shift_ratings',
  ProviderEndorsement: 'providers/:providerId/shift_endorsements/shift/:shiftId',
  ProviderEndorsementDefinitions: 'providers/shift_endorsements/definitions',
  ResendTeamAdministratorInvitation: 'teams/:teamId/administrator_invitations/:teamAdministratorInvitationId/resend',
  TeamAdministratorInvitation: 'teams/:teamId/administrator_invitations/:teamAdministratorInvitationId',
  TeamAdministratorInvitations: 'teams/:teamId/administrator_invitations',
  TeamAdministrator: 'teams/:teamId/administrators/:teamAdministratorId',
  TeamAdministrators: 'teams/:teamId/administrators',
  TeamFacility: 'teams/:teamId/facilities/:facilityId',
  TeamFacilities: 'teams/:teamId/facilities',
  TeamInvitations: 'teams/invitations/:uuid',
  ResendTeamMemberInvitation: 'teams/:teamId/member_invitations/:teamMemberInvitationId/resend',
  TeamMemberInvitation: 'teams/:teamId/member_invitations/:teamMemberInvitationId',
  TeamMemberInvitations: 'teams/:teamId/member_invitations',
  TeamMemberFacility: 'teams/:teamId/members/:teamMemberId/facilities/:facilityId',
  TeamMemberFacilities: 'teams/:teamId/members/:teamMemberId/facilities',
  TeamMember: 'teams/:teamId/members/:teamMemberId',
  TeamMembers: 'teams/:teamId/members',
  TeamPersonFacility: 'teams/:teamId/people/:clientId/facilities/:facilityId',
  TeamPersonFacilities: 'teams/:teamId/people/:clientId/facilities',
  TeamPersonUnassignedFacilities: 'teams/:teamId/people/:clientId/unassigned-facilities',
  TeamPerson: 'teams/:teamId/people/:clientId',
  TeamPeople: 'teams/:teamId/people',
  UserShiftOpenings: 'user/shift_openings',
  OnShiftShiftProcessingStatus: 'onshift/:shiftId/shift_processing_status',

  // Non-API prefixed endpoints
  FacilityFeatureFlagExport: 'facility_feature_flag/:id/export',
  FacilityFeatureFlagImport: 'facility_feature_flag/:id/import',
});
