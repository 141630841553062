import Enum from 'enum';

export const Resources = new Enum({
  ShiftOpeningsBulk: 'shift_openings/bulk',
  ShiftOpenings: 'shift_openings',
  ShiftOpening: 'shift_openings/:shiftOpeningId',
  ShiftOpeningRate: 'shift_openings/:shiftOpeningId/rate',
  ShiftAssignments: 'shift_assignments',
  ShiftAssignment: 'shift_assignments/:shiftAssignmentId',
  ShiftRequest: 'shift_requests/:shiftRequestId',
  ShiftRequests: 'shift_requests',
  ShiftRequestStatus: 'shift_requests/:shiftRequestId/status',
  ShiftShiftRequests: 'shifts/:shiftId/requests',
  ShiftCancellationReasons: 'cancellation-reasons',
  // Direct Connect
  ShiftDispatch: 'shifts/:shiftId/shift-dispatch',
  ShiftDispatchShiftEligibilityData: 'shifts/shift-dispatch/shift-eligibility-data/:facilityId',
  ShiftDispatchConfig: 'shifts/shift-dispatch/config',
});
