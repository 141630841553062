export default class ExceptionInterpreter {
  static isHttpConflictException(e) {
    return this.isHttpResponseExceptionWithStatus(e, 409);
  }
  static isUnauthorizedException(e) {
    return this.isHttpResponseExceptionWithStatus(e, 401) || this.isHttpResponseExceptionWithStatus(e, 403);
  }
  static isHttpResponseExceptionWithStatus(e, status) {
    if (this.isHttpResponseException(e) === false) {
      return false;
    }
    return e.response.status === status;
  }
  static isHttpResponseException(e) {
    return _.has(e, 'response.status');
  }
  static isCancelledRequestException(e) {
    return e.toString() === 'Cancel';
  }
}
