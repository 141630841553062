import UrlAssembler from '@/vendor/url-assembler';
import {
  Resources as ClientFacilityTeamShiftV1
} from '@/services/shiftkeyAPI/resources/ResourcesClientFacilityTeamShift';
import {
  Resources as ClientFacilityTeamShiftV2
} from '@/services/shiftkeyAPI/resources/ResourcesV2ClientFacilityTeamShift';
import { Resources as ShiftV1 } from '@/services/shiftkeyAPI/resources/ResourcesShift';
import { Resources as PaymentsAndBillingV1 } from '@/services/shiftkeyAPI/resources/ResourcesPaymentsAndBilling';
import { Resources as PaymentsAndBillingV2 } from '@/services/shiftkeyAPI/resources/ResourcesV2PaymentsAndBilling';
import { Resources as Working } from '@/services/shiftkeyAPI/resources/ResourcesWorking';
import { Resources as BrowsingV1 } from '@/services/shiftkeyAPI/resources/ResourcesBrowsingV1';
import { Resources as CredentialingV1 } from '@/services/shiftkeyAPI/resources/ResourcesCredentialing';
import { Resources as CredentialingV2 } from '@/services/shiftkeyAPI/resources/ResourcesV2Credentialing';

class _UrlBuilder {
  constructor(version) {
    this.base = `api/${version}/`;
  }

  /**
   * Returns prefix for provided resource.
   *
   * @param resource
   * @returns {string}
   */
  static getUrlPrefix(resource) {
    if (PaymentsAndBillingV1.isDefined(resource.key) || PaymentsAndBillingV2.isDefined(resource.key)) {
      return 'payments_and_billing/';
    }
    if (ClientFacilityTeamShiftV1.isDefined(resource.key) || ClientFacilityTeamShiftV2.isDefined(resource.key)) {
      return 'cfts/';
    }
    if (ShiftV1.isDefined(resource.key)) {
      return 'cfts/shifts/';
    }
    if (Working.isDefined(resource.key)) {
      return 'working/';
    }
    if (BrowsingV1.isDefined(resource.key)) {
      return 'browsing/';
    }
    if (CredentialingV1.isDefined(resource.key) || CredentialingV2.isDefined(resource.key)) {
      return 'credentialing/';
    }
    return '';
  }
  /**
   * Builds the url for the provided resource given the provided options.
   * Options is expected to be an object containing the url params and query objects
   *
   * Ex:
   * {
   *    params: {
   *         user_id: 25
   *    }
   *    query: {
   *        sort: 'ascending'
   *    }
   * }
   *
   * @param resource
   * @param options
   */
  resourceURL(resource, options) {
    const prefix = _UrlBuilder.getUrlPrefix(resource);
    const assembler = UrlAssembler(window.location.origin)
      .prefix(prefix + this.base)
      .template(resource.value)
      .query(_UrlBuilder.query(options))
      .param(_UrlBuilder.params(options));
    return assembler.toString();
  }
  /**
   * Extracts the query object from options
   *
   * @param options
   * @returns {{}}
   */
  static query(options) {
    if (options === null || typeof options !== 'object') {
      return {};
    }
    if (options.query === null || typeof options.query !== 'object') {
      return {};
    }
    return options.query;
  }
  /**
   * Extracts the param object from options
   *
   * @param options
   * @returns {{}}
   */
  static params(options) {
    if (options === null || typeof options !== 'object') {
      return {};
    }
    if (options.params === null || typeof options.params !== 'object') {
      return {};
    }
    return options.params;
  }
}

export default _UrlBuilder;
