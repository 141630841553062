import { employeeService } from "@/services/userService/EmployeeService";

export default [
  {
    action: 'fas fa-tachometer-alt',
    title: 'Dashboard',
    url: '/dashboard',
  },
  {
    action: 'far fa-calendar-alt',
    title: 'Schedule',
    url: '/schedule',
  },
  {
    action: 'far fa-credit-card',
    title: 'Payments',
    items: [
      {
        title: 'Payments Report',
        url: '/payments/payments-report',
      },
      {
        title: '1099s',
        url: '/payments/irs1099s',
      },
    ],
  },
  {
    action: 'far fa-id-card',
    title: 'Credentials',
    url: '/credentials',
    blank: true,
    useRouter: false,
  },
  {
    action: 'fas fa-cogs',
    title: 'Settings',
    items: [
      {
        title: 'Regions',
        url: '/settings/regions',
      },
      {
        title: 'Skills',
        url: '/settings/skills',
      },
      {
        title: 'Payment Method',
        url: '/settings/payment-method',
      },
      {
        title: 'Notifications',
        url: '/settings/notifications',
      },
    ],
  },
  {
    action: 'fas fa-hands-helping',
    title: 'Help',
    items: [
      {
        title: 'Help Center',
        url: import.meta.env.VITE_SHIFTKEY_HELP_LINK,
        blank: true,
        useRouter: false,
      },
      {
        title: 'Contact Us',
        url: '/help',
      },
    ],
  },
  {
    action: 'fas fa-cloud-download-alt',
    title: 'Downloads',
    url: '/downloads',
  },
  {
    action: 'fas fa-user-circle',
    title: 'Account',
    items: [
      {
        title: 'Profile',
        url: '/account/profile',
      },
      {
        title: 'Terms of Service',
        url: '/tos-provider',
        blank: true,
        useRouter: false,
      },
      {
        title: 'Privacy Policy',
        url: '/privacy-policy-provider',
        blank: true,
        useRouter: false,
      },
      {
        title: 'Privacy Choices',
        useRouter: false,
      },
      {
        title: 'Logout',
        url: '/auth/logout',
      },
    ],
  },
]
