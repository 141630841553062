export default [
  {
    action: 'far fa-id-card',
    title: 'Credentials',
    uri: 'credentials',
    useRouter: false,
    source: 'onboarding',
  },
  {
    action: 'far fa-money-bill-1',
    title: 'Payment Method',
    uri: 'payment-method',
    useRouter: false,
    source: 'onboarding',
  },
  {
    action: 'fas fa-user-circle',
    title: 'Logout',
    url: '/auth/logout',
  },
];
