import Vue from 'vue';
import Enum from 'enum';

/**
 * Global API Event Bus
 */

class _ErrorBus {
  constructor() {
    this.bus = new Vue();
    this.events = new Enum([
      'Error',
    ])
  }

  /**
   * Emits the Error event.
   * @param content - an object containing the headline, details, and acknowledgeText to be displayed
   * @param packet - optional object to store with the error
   * @param vue - the origin of the error
   */

  emitError(content, packet, vue) {
    this.bus.$emit(this.events.Error.toString(), content, packet, vue);
  }
}

export const ErrorBus = new _ErrorBus();
