import Vuetify from 'vuetify/lib';
import appColors from '@styles/export.scss?export';
import '@mdi/font/css/materialdesignicons.css';

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        primary: appColors['sk-color-primary'],
        secondary: appColors['sk-color-secondary'],
        accent: appColors['sk-color-primary'],
        success: appColors['sk-color-primary'],
        error: appColors['sk-error'],
        warning: appColors['sk-warning'],
        info: appColors['sk-color-default'],

        neu1: appColors['sk-neu1'],
        neu2: appColors['sk-neu2'],
        neu3: appColors['sk-neu3'],
        neu4: appColors['sk-neu4'],
        neu7: appColors['sk-neu7'],

        purple: appColors['sk-purple'],
        indigo: appColors['sk-indigo'],
        blue: appColors['sk-blue'],
        teal: appColors['sk-teal'],

        brand1: appColors['sk-brand1'],
        brand2: appColors['sk-brand2'],

        yellow: appColors['sk-yellow'],
        orange: appColors['sk-orange'],
        pink: appColors['sk-pink'],
      },
    },
  },
});
