import { Resources } from '../Resources';
import BaseResource from '../BaseResource';

export default class User extends BaseResource {
  /**
   * GET the resource
   * @returns {Promise}
   */
  get(params) {
    return this.performGet(Resources.User, params);
  }

  /**
   * GET isNursysUser
   *
   * @returns {Promise}
   */
  isNursysUser(params) {
    return this.performGet(Resources.UserIsNursys, params);
  }

  /**
   * PUT undoDeletion
   *
   * @returns
   */
  restore(params) {
    return this.performPut(Resources.UserRestore, params);
  }
}
