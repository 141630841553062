import employeeNavigationConfig from '@/services/userService/navigationConfig/employeeNavigationConfig';
import providerNavigationConfig from '@/services/userService/navigationConfig/providerNavigationConfig';
import onboardingBranchNavigationConfig from '@/services/userService/navigationConfig/onboardingBranchNavigationConfig';
import clientNavigationConfig from '@/services/userService/navigationConfig/clientNavigationConfig';
import { User } from '@/services/shiftkeyAPI/ShiftKeyAPI';
import { OAIService } from '@/services/OAIService/OAIService';
import { IN_APP_NOTIFICATION, PROVIDER_RATING } from '@/data/Facility/FeatureFlags';

const userResource = new User();

/**
 * Service to access info about the currently logged in user
 */

export class UserService {
  /**
   * Constructs the UserService and sets the user data
   *
   * @param userData
   */
  constructor(userData = null) {
    this.userData = userData || window.user;
  }
  watchUserActivityStatus() {
    let secondsSinceLastActivity = 0;
    setInterval(() => {
      secondsSinceLastActivity++;
    }, 1000);
    const activityCb = () => {
      secondsSinceLastActivity = 0;
    };
    const activityEvents = [
      'mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart',
    ];
    activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, activityCb, true);
    });
    this.userBecameActive();
    setInterval(() => {
      if (secondsSinceLastActivity < 60) {
        this.userBecameActive();
      }
    }, 60 * 1000);
  }
  userBecameActive() {
    axios.post('/auth/prolong-session');
  }
  checkNotifications() {
    if (
      this.isClient()
      && this.hasFacilityFeatureFlagEnabled(IN_APP_NOTIFICATION)
    ) {
      const notificationStoreInstance = notificationStore();

      notificationStoreInstance.getCount();
      setInterval(() => {
        notificationStoreInstance.getCount();
      }, 300000);
    }
  }
  /**
   * Refreshes the user's data
   *
   * @return {Promise}
   */
  refresh(params = {}) {
    return new Promise((resolve) => {
      userResource
        .get(params)
        .then((response) => {
          this.userData = response.data;
          resolve(this.userData);
        })
        .catch(e => {
        });
    });
  }
  /**
   * Return true if the logged in user is impersonated.
   *
   * @returns {boolean}
   */
  isImpersonated() {
    return !!window.impersonated;
  }
  /**
   * Return true if the logged in user is a provider
   *
   * @return {*|boolean}
   */
  isProvider() {
    return _.has(this, 'userData.role') && this.userData.role === 'Provider';
  }
  /**
   * Return true if the logged in user is an OnBoarding
   *
   * @return {*|boolean}
   */
  isOnBoarding() {
    return _.has(this, 'userData.role') && this.userData.role === 'OnBoarding';
  }
  /**
   * Returns true if the logged in user is a Client
   *
   * @returns {boolean}
   */
  isClient() {
    return _.has(this, 'userData.role') && this.userData.role === 'Client';
  }
  /**
   * Returns true if the logged in user has Administrator role
   *
   * @returns {boolean}
   */
  isAdministrator() {
    return _.has(this, 'userData.role') && this.userData.role === 'Administrator';
  }
  /**
   * Returns true if the logged in user is an Account Manager
   *
   * @returns {boolean}
   */
  isAccountManager() {
    return _.has(this, 'userData.role') && this.userData.role === 'Account Manager';
  }
  /**
   * Returns true if the logged in user is a QA
   *
   * @returns {boolean}
   */
  isQA() {
    return _.has(this, 'userData.role') && this.userData.role === 'QA';
  }
  /**
   * Returns true if the logged in user is a AR Factoring
   *
   * @returns {boolean}
   */
  isARFactoring() {
    return _.has(this, 'userData.role') && this.userData.role === 'AR Factoring';
  }
  /**
   * Returns true if the logged has superuser attribute
   *
   * @returns {boolean}
   */
  isSuperuser() {
    return _.has(this, 'userData.superuser') && this.userData.superuser === true;
  }
  /**
   * Returns true if the logged in user is a employee
   *
   * @returns {boolean}
   */
  isEmployee() {
    return this.isAdministrator() || this.isQA() || this.isAccountManager() || this.isARFactoring();
  }
  /**
   * Returns true if the logged in user is a dormant
   *
   * @returns {boolean}
   */
  isDormant() {
    return _.has(this, 'userData.dormant') && this.userData.dormant === true;
  }
  /**
   * Returns configuration for the navigation panel
   * specific to the logged in user
   *
   * @returns {Array}
   */
  navigationPanelConfig() {
    if (this.isEmployee()) {
      return employeeNavigationConfig;
    }

    if (this.isClient()) {
      return clientNavigationConfig;
    }

    if (this.isProvider()) {
      return providerNavigationConfig;
    }

    if (this.isOnBoarding() && this.userData.is_user_eligible_for_branch) {
      return onboardingBranchNavigationConfig;
    }

    throw new Error('Unknown user type');
  }
  /**
   * Returns the provider-id if the user is a provider, null otherwise
   *
   * @returns {Integer|null}
   */
  providerId() {
    if (this.userData.provider && this.userData.provider.id) {
      return this.userData.provider.id;
    }
    return null;
  }
  /**
   * Returns the provider-id if the user is a provider.
   * Otherwise throws exception
   *
   * @return {Integer|null}
   * @throws {Error}
   */
  providerIdOrFail() {
    this.ensureUserIsProvider();
    return this.providerId();
  }
  /**
   * Return the speciality-id if the user is a provider.
   * Otherwise throws an exception
   * @returns {Integer}
   * @throws {Error}
   */
  providerSpecialtyId() {
    const id = (((this.userData || {}).provider || {}).specialty || {}).id;
    if (id) {
      return id;
    }
    return null;
  }
  /**
   * Return the speciality-id if the user is a provider.
   * Otherwise throws an exception
   * @returns {Integer}
   * @throws {Error}
   */
  providerSpecialtyIdOrFail() {
    this.ensureUserIsProvider();
    return this.providerSpecialtyId();
  }
  /**
   * Returns the skill-ids if the user is a provider, null otherwise
   *
   * @returns {Array | null}
   */
  providerSkillIds() {
    const skills = ((this.userData || {}).provider || {}).skills;
    if (skills) {
      return skills.map(skill => skill.id);
    }
    return null;
  }
  /**
   * Returns the skill-ids if the user is a provider.
   * Otherwise throws an exception.
   * @returns {Array}
   * @throws {Error}
   */
  providerSkillIdsOrFail() {
    this.ensureUserIsProvider();
    return this.providerSkillIds();
  }
  /**
   * Returns the regions-ids if the user is a provider, null otherwise
   *
   * @returns {Array | null}
   */
  providerRegionIds() {
    const regions = ((this.userData || {}).provider || {}).regions;
    if (regions) {
      return regions.map(region => region.id);
    }
    return null;
  }
  /**
   * Returns the region-ids if the user is a provider.
   * Otherwise throws an exception.
   * @returns {Array}
   * @throws {Error}
   */
  providerRegionIdsOrFail() {
    this.ensureUserIsProvider();
    return this.providerRegionIds();
  }
  distanceFromGeoLocation(geoLocation) {
    const addressGeoLocation = this.userData.address.geolocation;
    if (!addressGeoLocation) {
      return null;
    }
    const radlat1 = Math.PI * addressGeoLocation.latitude / 180;
    const radlat2 = Math.PI * geoLocation.latitude / 180;
    const theta = addressGeoLocation.longitude - geoLocation.longitude;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }
  /**
   * Throws an exception if user is not a provider
   * @throws {Error}
   */
  ensureUserIsProvider() {
    this.ensureUserRole('Provider');
  }
  ensureUserRole(role) {
    if (this.userData.role !== role) {
      throw new Error(`User with role ${this.userData.role} is not a ${role}`);
    }
  }
  /**
   * Return notification phone.
   *
   * @returns String
   */
  notificationPhone() {
    return this.isClient() ? this.userData.secondary_phone : this.userData.phone;
  }
  /**
   * Returns the version of currently accepted TOS,
   * null if no version accepted or state unknown.
   *
   * @return {Number|null}
   */
  acceptedTOSVersion() {
    return _.has(this, 'userData.accepted_tos_version') ?
      Number(this.userData.accepted_tos_version) :
      null;
  }
  /**
   * Returns the current version of TOS,
   * null if unknown or could not be parsed.
   *
   * @return {Number|null}
   */
  currentTOSVersion() {
    const currentTOSVersion = import.meta.env.VITE_CURRENT_TOS_VERSION;

    return !!currentTOSVersion ?
      Number(currentTOSVersion) :
      null;
  }
  /**
   * Returns if User has accepted current TOS version,
   * false otherwise.
   * Always true for Users who does not need to accept TOS.
   *
   * @return {Boolean}
   */
  hasAcceptedCurrentTOS() {
    if (this.isImpersonated()) {
      return true;
    }
    if (!this.isProvider() && !this.isClient()) {
      return true;
    }

    return this.acceptedTOSVersion() === this.currentTOSVersion();
  }
  hasCompletedOAI() {
    if (!this.isProvider() && !this.isClient()) {
      return true;
    }
    return this.userData?.oai?.completed_at !== null;
  }
  hasOwnOAI() {
    return this.userData?.oai?.opt_in === false;
  }
  hasAnyFacilityWithDigitalInvoicesEnabled() {
    return this.userData.has_any_facility_with_uses_digital_invoice;
  }
  hasAnyFacilityWithOrientationShiftsEnabled() {
    return this.userData.has_any_facility_with_orientation_enabled;
  }

  /**
   * @param {Number} flagId
   * @param {Number|null} facilityId If null, we're asking if any of the current
   *        user's facilities have this flag enabled.
   * @return {boolean}
   */
  hasFacilityFeatureFlagEnabled(flagId, facilityId = null) {
    const facilityFeatureFlags = this.userData?.facility_feature_flags || [];

    return facilityFeatureFlags.some(
      (flag) => flag.flag_id === flagId
        && (facilityId === null || flag.facility_ids.includes(facilityId))
    );
  }
  getFacilitiesWithFeatureFlagEnabled(flagId) {
    const facilityFeatureFlags = this.userData?.facility_feature_flags || [];

    return facilityFeatureFlags.find((flag) => {
      return flag.flag_id === flagId;
    })?.facility_ids || [];
  }
  hasAnyFacilityWithFeatureFlagEnabled(flagId) {
    return this.getFacilitiesWithFeatureFlagEnabled(flagId).length > 0;
  }
  getClientFacilitiesWithPermissions() {
    return this.userData.facilities_with_permissions ?? [];
  }
  hasClientPermissionInFacility(permissionName, facilityId) {
    const facilitiesWithPermissions = this.getClientFacilitiesWithPermissions();
    const facilityWithPermissions = facilitiesWithPermissions.find((i) => i.facility_id === facilityId);

    if (!facilityWithPermissions) {
      return false;
    }
    return Boolean(facilityWithPermissions[permissionName]);
  }
  hasAnyFacilityWithProviderRatingEnabled() {
    return this.hasAnyFacilityWithFeatureFlagEnabled(PROVIDER_RATING);
  }
  logOut() {
    axios
      .post('/auth/logout', {})
      .then(() => {
        OAIService.clearLocalState();
        const { protocol } = window.location;
        const slashes = protocol.concat('//');
        const host = slashes.concat(window.location.host);
        window.location.href = `${host}/auth/login`;
      });
  }
  /**
   * Returns id of currently logged-in user
   * @returns {Integer|null}
   */
  userId() {
    return this.userData.id ?? null;
  }
  /**
   * Checks if logged-in user can use in-app notifications.
   * For now only clients have access to that feature and it uses shiftkey_settings instead of FFF.
   * @returns {boolean}
   */
  canUseInAppNotifications() {
    if (this.isClient()) {
      return Boolean(window.settings?.in_app_notifications);
    }
    return false;
  }
  getExperimentVariant(experiment) {
    return this.userData.experiments?.[experiment] ?? 'baseline';
  }
}

export const userService = new UserService();
