export default {
  options: {
    vs: {
      e: 15,
      i: 11,
      s: 11,
    },
    insecure: true,
    reminder: 0,
    // eslint-disable-next-line max-len
    text: 'Your web browser ({brow_name}) is out of date. Update your browser for more security, speed and the best experience on this site: <a{up_but}>UPDATE</a>',
    container: document.body,
  },
  test: false,
};
