/* eslint max-len: 0 */
import Enum from 'enum';

/**
 * Enum defining all available api v2 for "payments and billing" resources and their URL template
 */
export const Resources = new Enum({
  AccountBankInfo: 'account/bank_info',
  AccountPaymentMethod: 'account/payment_method',
  UserBankInfo: 'users/:userId/account/bank_info',

  // Branch
  BranchWalletStatus: 'branch/wallet_status',
  BranchDirectDebitStatus: 'branch/direct_debit_status',
  BranchWalletInfo: 'branch/wallet_info',
  BranchWallet: 'branch/wallet',
  BranchDirectDebit: 'branch/direct_debit',
  BranchUserPaymentMethod: 'branch/user/payment_method',
  BranchWalletInternalStatus: 'branch/wallet/user/:userId/wallet_internal_status',
  BranchWalletResetUser: 'branch/wallet/user/:userId/reset',
  // ~Branch

  // Plaid
  PlaidLinkCreate: 'plaid/link/create',
  PlaidLinkSuccess: 'plaid/link/success',
  // ~Plaid
});
