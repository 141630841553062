import moment from 'moment';

class _OAIService {
  #storage;
  #days;
  constructor() {
    this.#storage = localStorage;
    this.#days = Number(import.meta.env.VITE_OAI_ELECTION_DURATION_DAYS);
  }
  get days() {
    return this.#days;
  }
  modalShown() {
    this.#storage.setItem('oaiShown', '1');
  }
  modalNeedsToBeShown() {
    return !this.#storage.getItem('oaiShown') || this.#storage.getItem('oaiShown') !== '1';
  }
  clearLocalState() {
    this.#storage.removeItem('oaiShown');
  }
  isElectionEnd() {
    const oai = window.user?.oai;
    const electionPeriodEnd = moment(oai?.election_period_end ?? null);
    return electionPeriodEnd.isValid() && moment()
      .isAfter(electionPeriodEnd);
  }
}

export const OAIService = new _OAIService();
