import Enum from 'enum';

export const Resources = new Enum({
  Analytics_CompletedOnBoardingGeoLocations: 'analytics/onboarding/completed-geolocations',
  Analytics_OnBoardingCompletionTimeBreakdown: 'analytics/onboarding/completion-time-breakdown',
  Analytics_OnBoardingGeoLocations: 'analytics/onboarding/geolocations',
  Analytics_OnBoardingLicensureValidationStatus: 'analytics/onboarding/licensure-validation-status',
  Analytics_OnBoardingStepBreakdown: 'analytics/onboarding/step-breakdown',
  Analytics_StartedVsCompletedOnBoarding: 'analytics/onboarding/started-vs-completed',
  BackgroundChecks: 'background-checks',
  Credential: 'credentials/:credentialId',
  CredentialConstraintsHistory: 'credential-constraint-history',
  CredentialConstraintsHistoryShow: 'credential-constraint-history/:credential_constraints_history_id',
  CredentialConstraintsHistoryCancel: 'credential-constraint-history/:credential_constraints_history_id/cancel',
  CredentialConstraints: 'credentials/:credentialId/constraints',
  CredentialTypes: 'credential_types',
  Credentials: 'credentials',
  Credentials_DrugScreensConsider: 'credentials/drug-screens/consider',
  Credentials_DrugScreensPendingCollection: 'credentials/drug-screens/pending-collection',
  Credentials_DrugScreensPendingInvitation: 'credentials/drug-screens/pending-invitation',
  Credentials_DrugScreensPendingOrder: 'credentials/drug-screens/pending-order',
  Credentials_DrugScreensPendingResult: 'credentials/drug-screens/pending-result',
  Credentials_NurseLicensing: 'credentials/nurse-licensings/:nurse_licensing_id',
  Credentials_NurseLicensingATPs: 'credentials/nurse-licensings/:nurse_licensing_id/atps',
  Credentials_NurseLicensingAnchor: 'credentials/nurse-licensings/:nurse_licensing_id/anchor',
  Credentials_NurseLicensingLicenses: 'credentials/nurse-licensings/:nurse_licensing_id/licenses',
  Credentials_NurseLicensings: 'credentials/nurse-licensings',
  Credentials_NursingLicenseATPs: 'credentials/nursing-licenses/:license_id/atps',
  Credentials_NursingLicenseHistory: 'credentials/nursing-licenses/:license_id/history',
  Credentials_NursingLicenseStatus: 'credentials/nursing-licenses/:license_id/status',
  Credentials_UnverifiedDocuments: 'credentials/documents/unverified',
  Credentials_UnverifiedLicenses: 'credentials/licenses/queued',
  DocumentDefinition: 'document_definitions/:documentDefinitionId',
  DocumentDefinitionRejectionNotes: 'document_definitions/:documentDefinitionId/rejection_notes',
  UpdateDocumentDefinitionRejectionNote: 'document_definitions/:documentDefinitionId/rejection_notes/:rejectionNote',
  DocumentDefinitions: 'document_definitions',
  DocumentDefinitionsRejectionNotes: 'document_definitions/rejection_notes',
  DocumentValidationLog: 'insights/document_validation_log',
  DrugScreenExclusion: 'drug_screen_exclusions',
  DeleteDrugScreenExclusion: 'drug_screen_exclusions/:drugScreenExclusion',
  DynamicDocumentFields: 'document_definitions/:documentDefinitionId/dynamic_document_fields',
  LicenseDefinitions: 'license_definitions',
  LicensingValidationLog: 'insights/licensing_validation_log',
  NursingLicenseValidationLog: 'insights/nursing_license_validation_log',
  OnBoarding: 'onboarding/:onBoardingId',
  OnBoardingAddress: 'onboarding/:onBoardingId/address',
  OnBoardingExperience: 'onboarding/:onBoardingId/experience',
  OnBoardingProfile: 'onboarding/:onBoardingId/profile',
  OnBoardingRegion: 'onboarding/:onBoardingId/regions/:regionId',
  OnBoardingRegions: 'onboarding/:onBoardingId/regions',
  OnBoardingSteps: 'onboarding_steps',
  OnBoardings: 'onboarding',
  OptionalUserCredentials: 'users/:userId/optional_credentials',
  ProviderSuspensionDataTemplate: 'suspension_data/template',
  ProviderSuspensionDataType: 'suspension_data/type',
  Region: 'regions/:regionId',
  Regions: 'regions',
  RequiredUserCredential: 'users/:userId/required_credentials/:credentialDefinitionId',
  RequiredUserCredentials: 'users/:userId/required_credentials',
  Skill: 'skills/:skillId',
  Skills: 'skills',
  Specialties: 'specialties',
  Specialty: 'specialties/:specialtyId',
  States: 'states',
  UserBackgroundCheck: 'users/:userId/background-check',
  UserBackgroundCheckStage: 'users/:userId/background-check-stage',
  UserCredential: 'users/:userId/credentials/:credentialId',
  UserCredentials: 'users/:userId/credentials',
  UserCredentialsLicenseHistory: 'user/credentials/licenses/:licenseId/history',
  UserCredentialsLicenseValidationData: 'user/credentials/licenses/:license_id/validation_data',
  UserCredentialsNurseLicensing: 'user/credentials/nurse-licensing',
  UserDrugScreen: 'users/:userId/drug-screen',
  UserDrugScreenStage: 'users/:userId/drug-screen-stage',
  UserIsNursys: 'users/:user_id/is-nursys',
  UserOnBoardingStatus: 'user/onboarding/status',
  UserRegion: 'user/regions/:region_id',
  UserRegions: 'user/regions',
  UserRequiredDocument: 'user_required_documents/:userRequiredDocumentId',
  UserRequiredDocuments: 'user_required_documents',
  UserSkill: 'user/skills/:skill_id',
  UserSkills: 'user/skills',
  UsersCredentialsNurseLicensing: 'users/:user_id/credentials/nurse-licensing',
  CredentialingProviderExperience: 'providers/:providerId/experience',
  CredentialingProvider: 'providers/:providerId',
  CredentialingProviderSuspension: 'providers/:providerId/suspension',
  CredentialingProviderRegions: 'providers/:providerId/regions',
  CredentialingProviderRegion: 'providers/:providerId/regions/:regionId',
});
